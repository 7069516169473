<template>
  <div class="app_con">
    <app-list :remote="remote" :props="props" :formProps="formProps" :searchQuery="searchQuery"
      :searchControl="searchControl" :apiName="apiName" :editTitle="'商品'"
      :btnFlag="{ addShow: false, editShow: false, deleteShow: false }">
      <!-- <div class="search" slot="tool">
        <el-input
          placeholder="根据标题查询"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">标题</template>
        </el-input>

        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">类别</div>
          <el-select
            filterable
            v-model="searchQuery.typeId"
            clearable
            placeholder="根据类别筛选"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div> -->
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    // let self = this;
    return {
      remote: api.common,
      apiName: "/payScanOrder",
      searchVal: "",
      jobList: [],
      typeList: [],
      bntLoading: false,
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [8, 8, 8, 8],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: { num: 0 },

      props: [
        {
          label: "流水号",
          prop: "payNo",
          align: "center",
          width: 400,
        },
        {
          label: "支付时间",
          prop: "payTime",
          align: "center",
        },

        {
          label: "买家",
          prop: "personnelName",
          align: "center",
        },

        {
          label: "类型",
          prop: "payType",
          type: 'html',
          formatter: function (row) {
            let code = "";
            if (row.payType == 0) {
              code = "<span class='font-blue'>微信支付</span>"
            }
            else {
              code = "<span>余额支付</span>"
            }
            return code;
          },
        },


        {
          label: "应付金额（元）",
          prop: "shouldPrice",
          align: "center",
        },
        {
          label: "实际支付（元）",
          prop: "price",
          align: "center",
        },

        {
          label: "抵扣金额（元）",
          prop: "deductionPrice",
          align: "center",
        },

        {
          label: "抵扣积分",
          prop: "deductionIntegral",
          align: "center",
        },

        {
          label: "状态",
          prop: "status",
          align: "center",
          type: 'html',
          formatter: function (row) {
            let code = "";
            if (row.status == 1) {
              code = "<span class='font-blue'>已支付</span>"
            }
            else if (row.status == 2) {
              code = "<span class='font-red'>已取消</span>"
            }
            else {
              code = "<span>待支付</span>"

            }

            return code;

          },
        },




        // {
        //   label: "操作",
        //   prop: "qrcode",
        //   align: "center",
        //   type: "operationLink",
        //   formatter: function (row) {
        //     const buttonList = [
        //       {
        //         text: "强踢",
        //         size: "mini",
        //         data: row,
        //         show: true,
        //         icon: "el-icon-share",
        //         type: "primary",
        //         handler: function () {
        //           self.offline(row.id);
        //         },
        //       },
        //     ];
        //     return buttonList;
        //   },
        // },
      ],
      formProps: [],
    };
  },
  created() { },
  methods: {
    //同步默认商品到商户

    async offline(id) {
      this.$confirm("此操作将用户强制下线, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await api.common
            .commonGet({
              apiName: "/system/monitor/activeUser/offline",
              id: id,
            })
            .then((res) => {
              if (res.code == 200) {
                this.searchQuery.num++;
                this.$message({ message: res.message, type: "success" });
              } else {
                this.$message(res.message);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}

.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
